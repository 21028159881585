<template>
  <div class="user">
    <sub-banner :title="banner.title" :subTitle="banner.subTitle" :bannerBg="banner.bannerBg"></sub-banner>
    <div class="content">
      <div class="info">
        <h3>基本信息</h3>
        <p class="phone">{{userInfo.phone}}</p>
        <p class="email">{{userInfo.email}}</p>
        <p class="company">{{userInfo.company ? userInfo.company : '-'}}</p>
        <p class="city">{{userInfo.province ? userInfo.province : '-'}}{{userInfo.city}}</p>
        <p class="time">{{userInfo.createTime | moment('YYYY-MM-DD HH:mm')}}</p>
      </div>
      <div class="order">
        <h3>订单管理</h3>
        <div class="list-wrap" ref="listWrap">
          <table v-if="orderList.length > 0" class="list" cellspacing="0">
            <tr>
              <th v-for="column of gpuColumn" :key="column.dataIndex">
                <span>{{column.title}}</span>
              </th>
              <th>
                <span>操作</span>
              </th>
            </tr>
            <tr v-for="listItem of curList" :key="listItem.orderId">
              <td v-for="item of gpuColumn" :key="item.dataIndex">
                <template v-if="item.dataIndex === 'gpuCountLimit'">
                  <span style="display: inline-block; width: 55px;">{{listItem.gpuCountLimit}}</span>
                  <a v-show="listItem.gpuTypeLimitation && listItem.gpuTypeLimitation.length" class="gpu-limit-detail-btn" @click="gpuLimitDetail(listItem.gpuTypeLimitation)">详情</a>
                </template>
                <span v-else :name="item.dataIndex">{{listItem[item.dataIndex]}}</span>
              </td>
              <td>
                <van-button @click="handleViewList(listItem.userOfferId)"
                  class="operate-btn"
                  type="info"
                  :disabled="_judgeDisabled(listItem.status)"
                >
                  查看GPU列表
                </van-button>
                <van-button
                  class="operate-btn download"
                  type="info"
                  @click="handleActivate"
                  :disabled="_judgeDisabled(listItem.status)"
                >下载激活文件
                </van-button>
              </td>
            </tr>
          </table>
          <van-empty v-else description="暂无数据" />
        </div>
        <van-pagination
          v-if="orderList.length > 0"
          v-model="currentPage"
          :total-items="orderList.length"
          :prev-text="' '"
          :next-text="' '"
          :items-per-page="pageSize"
          @change="handlePageChange"
        />
      </div>
    </div>
    <van-popup v-model="gpuPopShow" class="gpu-limit-modal">
      <dl class="gpu-limit-list">
        <dt>
          <span>型号</span>
          <span>上限</span>
        </dt>
        <dd v-for="item in gpuLimitList" :key="item.gpuType">
          <span>{{item.gpuType}}</span>
          <span>{{item.limit}}</span>
        </dd>
      </dl>
    </van-popup>
  </div>
</template>

<script>
import subBanner from '@/components/subBanner'
import { mapGetters } from 'vuex'
import pageMixin from '@/mixins/pageMixin'

const gpuColumn = [
  {
    title: '订单号',
    dataIndex: 'orderId'
  },
  {
    title: '类型',
    dataIndex: 'offerIdName'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime'
  },
  {
    title: '激活时间',
    dataIndex: 'activateTime'
  },
  {
    title: '过期时间',
    dataIndex: 'expireTime'
  },
  {
    title: 'GPU数量上限',
    dataIndex: 'gpuCountLimit'
  },
  {
    title: '状态',
    dataIndex: 'statusName'
  }
]

export default {
  name: 'user',
  mixins: [pageMixin],
  components: {
    subBanner
  },
  data () {
    return {
      gpuColumn,
      orderList: [],
      curList: [],
      gpuLimitList: [],
      gpuPopShow: false,
      totalPage: 0,
      currentPage: 1,
      pageSize: 5,
      banner: {
        title: '个人中心',
        subTitle: 'Personal center',
        bannerBg: require('@/assets/images/user/banner.jpg')
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  async mounted () {
    try {
      this.orderList = await this.api.offersGet()
      this.orderList.filter((data) => {
        data.offerIdName = this.$options.filters.typeFilter(data.offerId)
        data.statusName = this.$options.filters.statusFilter(data.status)
        data.createTime = this.$options.filters.moment(data.createTime)
        data.activateTime = this.$options.filters.moment(data.activateTime)
        data.expireTime = this.$options.filters.moment(data.expireTime)
      })
      this.totalPage = Math.ceil(this.orderList / this.pageSize)
      this.getCurrentPageData()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    _judgeDisabled (status) {
      return status !== 1
    },
    handleActivate () {
      this.$toast('移动端不支持下载激活文件\n请到电脑浏览器上进行操作')
    },
    handleViewList (id) {
      this.$router.push(
        {
          path: '/user/gpulist',
          query: {
            key: id
          }
        }
      )
    },
    getCurrentPageData () {
      const begin = (this.currentPage - 1) * this.pageSize
      const end = this.currentPage * this.pageSize
      this.curList = this.orderList.slice(begin, end)
    },
    handlePageChange (page) {
      this.currentPage = page
      this.$refs.listWrap.scrollLeft = 0
      this.getCurrentPageData()
    },
    gpuLimitDetail (data) {
      this.gpuLimitList = data
      this.gpuPopShow = true
    }
  }
}
</script>

<style lang="less" scoped>
  .user{
    .content{
      padding: 48px 30px;
      h3{
        font-size: 32px;
      }
      .info{
        padding: 0 20px;
        margin-bottom: 65px;
        h3{
          color: @black;
          margin-bottom: 42px;
        }
        p{
          padding-left: 48px;
          margin-bottom: 32px;
          line-height: 36px;
          color: @grey;
          font-size: 26px;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 29px 29px;
          &.phone{
            background-image: url("~@/assets/images/user/phone.png");
          }
          &.email{
            background-image: url("~@/assets/images/user/email.png");
          }
          &.company{
            background-image: url("~@/assets/images/user/company.png");
          }
          &.city{
            background-image: url("~@/assets/images/user/city.png");
          }
          &.time{
            background-image: url("~@/assets/images/user/time.png");
          }
        }
      }
      .order{
        h3{
          color: @blue;
          padding-left: 20px;
          margin-bottom: 30px;
        }
        .list-wrap{
          width: 100%;
          height: auto;
          min-height: 30vh;
          margin-bottom: 60px;
          overflow-x: auto;
          &::-webkit-scrollbar-track-piece {
            background-color: rgba(0, 0, 0, 0);
            border-left: 1px solid rgba(0, 0, 0, 0);
          }
          &::-webkit-scrollbar {
            width: 5px;
            height: 10px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.1);
            background-clip: padding-box;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            min-height: 25px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 0, 0, 0.1);
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
          }
          .list{
            color: @grey2;
            font-size: 24px;
            border-collapse: collapse;
            tr{
              border-bottom: 1px #E8E8E8 solid;
            }
            th, td{
              padding: 0 15px;
              white-space: nowrap;
            }
            th{
              min-width: 150px;
              line-height: 78px;
              background: @blue;
              text-align: left;
              color: #fff;
              font-size: 26px;
            }
            td{
              line-height: 70px;
              &:first-child{
                color: @blue;
              }
            }
            .operate-btn{
              width: 184px;
              height: 52px;
              line-height: 52px;
              color: #fff;
              font-size: 24px;
              border: 0;
              &.download{
                background: #1A3E83;
                margin-left: 24px;
              }
            }
          }
        }
      }
    }
    .gpu-limit-detail-btn {
      color: @blue;
    }
    .gpu-limit-modal {
      width: 80vw;
      height: 40vh;
    }
    .gpu-limit-list {
      padding: 40px;
      dt {
        font-weight: bold;
      }
      dt, dd {
        display: flex;
        justify-content: space-between;
        padding: 10px 12px;
        font-size: 24px;
      }
    }
  }
</style>
